"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.debouncedRequestAvatar = void 0;
exports.requestAvatar = requestAvatar;
var _index = require("./store/index");
var _data_utils = require("./utils/data_utils");
var preload = _interopRequireWildcard(require("./utils/preload_utils"));
var f = _interopRequireWildcard(require("./utils/fn_utils"));
var l = _interopRequireWildcard(require("./core/index"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var _dataFns = (0, _data_utils.dataFns)(['avatar']),
  tget = _dataFns.tget,
  tset = _dataFns.tset;
var cache = {};
function requestAvatar(id, src) {
  if (cache[src]) {
    return update(id, src, cache[src].url, cache[src].displayName, true);
  }
  var provider = l.ui.avatarProvider((0, _index.read)(_index.getEntity, 'lock', id)).toJS();
  (0, _index.swap)(_index.updateEntity, 'lock', id, function (m) {
    m = tset(m, 'syncStatus', 'loading');
    m = tset(m, 'src', src);
    return m;
  });
  var url, displayName;
  provider.url(src, function (error, str) {
    if (error) return handleError(id, src);
    preload.img(str, function (error, img) {
      if (error) return handleError(id, src);
      url = img.src;
      if (displayName !== undefined) handleSuccess(id, src, url, displayName);
    });
  });
  provider.displayName(src, function (error, str) {
    if (error) return handleError(id);
    displayName = str;
    if (url !== undefined) handleSuccess(id, src, url, displayName);
  });
}
var debouncedRequestAvatar = f.debounce(requestAvatar, 300);
exports.debouncedRequestAvatar = debouncedRequestAvatar;
function handleSuccess(id, src, url, displayName) {
  cache[src] = {
    url: url,
    displayName: displayName
  };
  update(id, src, url, displayName);
}
function update(id, src, url, displayName) {
  var force = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
  (0, _index.swap)(_index.updateEntity, 'lock', id, function (m) {
    if (force || tget(m, 'src') === src) {
      m = tset(m, 'syncStatus', 'ok');
      m = tset(m, 'url', url);
      m = tset(m, 'src', src);
      m = tset(m, 'displayName', displayName);
    }
    return m;
  });
}
function handleError(id, src) {
  (0, _index.swap)(_index.updateEntity, 'lock', id, function (m) {
    return tget(m, 'src') === 'src' ? tset(m, 'syncStatus', 'error') : m;
  });
}
