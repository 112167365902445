"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.emailDomain = emailDomain;
exports.emailLocalPart = emailLocalPart;
exports.isEmail = isEmail;
exports.setEmail = setEmail;
exports.validateEmail = validateEmail;
var _trim = _interopRequireDefault(require("trim"));
var _isEmail2 = _interopRequireDefault(require("validator/lib/isEmail"));
var _index = require("./index");
var _enterprise = require("../connection/enterprise");
var i18n = _interopRequireWildcard(require("../i18n"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function validateEmail(str) {
  var strictValidation = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return isEmail(str, strictValidation);
}
function isEmail(str) {
  var strictValidation = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  if (typeof str !== 'string') {
    return false;
  }
  var trimmed = (0, _trim.default)(str);
  return strictValidation ? (0, _isEmail2.default)(str) : trimmed.indexOf('@') >= 0 && trimmed.indexOf('.') >= 0 && trimmed.indexOf(' ') === -1;
}
function setEmail(m, str) {
  var strictValidation = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  return (0, _index.setField)(m, 'email', str, function (str) {
    var validHRDEMail = (0, _enterprise.isHRDEmailValid)(m, str);
    return {
      valid: validateEmail(str, strictValidation) && validHRDEMail,
      hint: !validHRDEMail ? i18n.html(m, ['error', 'login', 'hrd.not_matching_email']) : undefined
    };
  });
}
function emailDomain(str) {
  if (!isEmail(str)) {
    return '';
  }
  return str.split('@')[1].toLowerCase();
}
function emailLocalPart(str) {
  var domain = emailDomain(str);
  return domain ? str.slice(0, -1 - domain.length) : str;
}
