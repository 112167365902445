"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.Auth0LockPasswordless = exports.Auth0Lock = void 0;
var _lock = _interopRequireDefault(require("./lock"));
var _passwordless = _interopRequireDefault(require("./passwordless"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var Auth0LockPasswordless = _passwordless.default;
exports.Auth0LockPasswordless = Auth0LockPasswordless;
var Auth0Lock = _lock.default;
exports.Auth0Lock = Auth0Lock;
var _default = _lock.default;
exports.default = _default;
