"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.syncRemoteData = syncRemoteData;
var _immutable = _interopRequireDefault(require("immutable"));
var _settings = require("./client/settings");
var _settings2 = require("./tenant/settings");
var _data = require("./sso/data");
var l = _interopRequireWildcard(require("./index"));
var _enterprise = require("../connection/enterprise");
var _sync = _interopRequireWildcard(require("../sync"));
var _web_api = _interopRequireDefault(require("./web_api"));
var _index2 = require("../core/index");
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
// shouldn't depend on this

function syncRemoteData(m) {
  if (l.useTenantInfo(m)) {
    m = (0, _sync.default)(m, 'client', {
      syncFn: function syncFn(m, cb) {
        return (0, _settings2.fetchTenantSettings)(l.tenantBaseUrl(m), cb);
      },
      successFn: function successFn(m, result) {
        return (0, _settings2.syncTenantSettingsSuccess)(m, l.clientID(m), result);
      }
    });
  } else {
    m = (0, _sync.default)(m, 'client', {
      syncFn: function syncFn(m, cb) {
        return (0, _settings.fetchClientSettings)(l.clientID(m), l.clientBaseUrl(m), cb);
      },
      successFn: _settings.syncClientSettingsSuccess
    });
  }
  m = (0, _sync.default)(m, 'sso', {
    conditionFn: function conditionFn(m) {
      return l.auth.sso(m) && l.ui.rememberLastLogin(m);
    },
    waitFn: function waitFn(m) {
      return (0, _sync.isSuccess)(m, 'client');
    },
    syncFn: function syncFn(m, cb) {
      (0, _data.fetchSSOData)(l.id(m), (0, _enterprise.isADEnabled)(m), function () {
        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }
        l.emitEvent(m, 'ssodata fetched', args);
        cb.apply(void 0, args);
      });
    },
    successFn: function successFn(m, result) {
      return m.mergeIn(['sso'], _immutable.default.fromJS(result));
    },
    errorFn: function errorFn(m, error) {
      if (error.error === 'consent_required') {
        l.warn(m, error.error_description);
      } else {
        // location.origin is not supported in all browsers
        var origin = location.protocol + '//' + location.hostname;
        if (location.port) {
          origin += ':' + location.port;
        }
        var appSettingsUrl = "https://manage.auth0.com/#/applications/".concat(l.clientID(m), "/settings");
        l.warn(m, "There was an error fetching the SSO data. This is expected - and not a problem - if the tenant has Seamless SSO enabled. If the tenant doesn't have Seamless SSO enabled, this could simply mean that there was a problem with the network. But, if a \"Origin\" error has been logged before this warning, please add \"".concat(origin, "\" to the \"Allowed Web Origins\" list in the Auth0 dashboard: ").concat(appSettingsUrl));
      }
    }
  });
  m = (0, _sync.default)(m, 'captcha', {
    syncFn: function syncFn(m, cb) {
      _web_api.default.getChallenge(m.get('id'), function (err, r) {
        cb(null, r);
      });
    },
    successFn: _index2.setCaptcha
  });
  m = (0, _sync.default)(m, 'passwordlessCaptcha', {
    syncFn: function syncFn(m, cb) {
      _web_api.default.getPasswordlessChallenge(m.get('id'), function (err, r) {
        cb(null, r);
      });
    },
    successFn: _index2.setPasswordlessCaptcha
  });
  return m;
}
