"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initPasswordless = initPasswordless;
exports.isEmail = isEmail;
exports.isSendLink = isSendLink;
exports.mustAcceptTerms = mustAcceptTerms;
exports.passwordlessConnection = passwordlessConnection;
exports.passwordlessStarted = passwordlessStarted;
exports.resend = resend;
exports.resendAvailable = resendAvailable;
exports.resendFailed = resendFailed;
exports.resendOngoing = resendOngoing;
exports.resendSuccess = resendSuccess;
exports.restartPasswordless = restartPasswordless;
exports.send = send;
exports.setPasswordlessStarted = setPasswordlessStarted;
exports.setResendFailed = setResendFailed;
exports.setResendSuccess = setResendSuccess;
exports.showTerms = showTerms;
exports.termsAccepted = termsAccepted;
exports.toggleTermsAcceptance = toggleTermsAcceptance;
var _immutable = _interopRequireWildcard(require("immutable"));
var l = _interopRequireWildcard(require("../../core/index"));
var _index2 = require("../../field/index");
var _phone_number = require("../../field/phone_number");
var _data_utils = require("../../utils/data_utils");
var _web_api = _interopRequireDefault(require("../../core/web_api"));
var _sync = _interopRequireDefault(require("../../sync"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var _dataFns = (0, _data_utils.dataFns)(['passwordless']),
  get = _dataFns.get,
  initNS = _dataFns.initNS,
  tget = _dataFns.tget,
  tremove = _dataFns.tremove,
  tset = _dataFns.tset;
function initPasswordless(m, opts) {
  // TODO: validate opts
  var send = opts.passwordlessMethod === 'link' ? 'link' : 'code';
  var mustAcceptTerms = !!opts.mustAcceptTerms;
  var showTerms = opts.showTerms === undefined ? true : !!opts.showTerms;
  m = initNS(m, (0, _immutable.Map)({
    send: send,
    mustAcceptTerms: mustAcceptTerms,
    showTerms: showTerms
  }));
  if (opts.defaultLocation && typeof opts.defaultLocation === 'string') {
    m = (0, _phone_number.initLocation)(m, opts.defaultLocation.toUpperCase());
  } else {
    m = (0, _sync.default)(m, 'location', {
      recoverResult: 'US',
      syncFn: function syncFn(m, cb) {
        return _web_api.default.getUserCountry(l.id(m), cb);
      },
      successFn: function successFn(m, result) {
        return (0, _phone_number.initLocation)(m, result);
      }
    });
  }
  return m;
}
function setResendStatus(m, value) {
  // TODO: check value
  return tset(m, 'resendStatus', value);
}
function setResendSuccess(m) {
  return setResendStatus(m, 'success');
}
function resendSuccess(m) {
  return resendStatus(m) == 'success';
}
function setResendFailed(m) {
  return setResendStatus(m, 'failed');
}
function resendFailed(m) {
  return resendStatus(m) == 'failed';
}
function resendOngoing(m) {
  return resendStatus(m) == 'ongoing';
}
function resend(m) {
  if (resendAvailable(m)) {
    return setResendStatus(m, 'ongoing');
  } else {
    return m;
  }
}
function resendStatus(m) {
  return tget(m, 'resendStatus', 'waiting');
}
function resendAvailable(m) {
  return resendStatus(m) == 'waiting' || resendStatus(m) == 'failed';
}
function restartPasswordless(m) {
  // TODO: maybe we can take advantage of the transient fields
  m = tremove(m, 'passwordlessStarted');
  m = tremove(m, 'resendStatus'); // only for link
  m = (0, _index2.clearFields)(m, ['vcode']); // only for code

  return l.clearGlobalError(m);
}
function send(m) {
  return get(m, 'send', isEmail(m) ? 'link' : 'code');
}
function isSendLink(m) {
  return send(m) === 'link';
}
function setPasswordlessStarted(m, value) {
  return tset(m, 'passwordlessStarted', value);
}
function passwordlessStarted(m) {
  return tget(m, 'passwordlessStarted', false);
}
function passwordlessConnection(m) {
  return l.connections(m, 'passwordless', 'email').get(0) || l.connections(m, 'passwordless', 'sms').get(0) || new _immutable.Map();
}
function isEmail(m) {
  var c = passwordlessConnection(m);
  return c.isEmpty() ? undefined : c.get('strategy') === 'email';
}
function showTerms(m) {
  return get(m, 'showTerms', true);
}
function mustAcceptTerms(m) {
  return get(m, 'mustAcceptTerms', false);
}
function termsAccepted(m) {
  return !mustAcceptTerms(m) || tget(m, 'termsAccepted', false);
}
function toggleTermsAcceptance(m) {
  return tset(m, 'termsAccepted', !termsAccepted(m));
}
